<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="이름">
          <Input placeholder="이름" size="large" v-model="form.name"></Input>
        </FormItem>
        <FormItem label="휴대폰 번호">
          <Input placeholder="제목" size="large" v-model="form.username"></Input>
        </FormItem>
        <FormItem label="베스트 리뷰">
          <Checkbox size="large" v-model="form.is_main">해당 리뷰는 베스트 리뷰입니다.</Checkbox>
        </FormItem>
        <FormItem label="이미지">
          <div class="wrapper" style="max-width: 360px">
            <CImageUpload v-model="form.image_id" :url.sync="form.image" ratio="16:9"></CImageUpload>
          </div>
        </FormItem>
        <FormItem label="본문">
          <Input type="textarea" placeholder="본문" :rows="10" size="large" v-model="form.body"></Input>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";

export default {
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      if (this.isModify) {
        return `review/${this.id}`
      } else {
        return `review`
      }
    }
  },
  data() {
    return {
      form: {
        name: null,
        username: '',
        body: '',
        is_best: false,
        image_id: null,
        image: null
      },
      meta: {
        lectures: [],
      }
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>